// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#verification-product{
    padding: 1rem 5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
#verification-product form{
    display: flex;
    flex-direction: column;
    gap: 2rem;

}
#verification-product form .not-main-p{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
@media (max-width: 992px){

#verification-product {
    padding: 1rem 2rem 2rem;
}

}
@media (max-width: 768px){
    #verification-product {
        padding: 1rem 1rem 2rem;
        justify-content: center;
    }

}`, "",{"version":3,"sources":["webpack://./src/styles/VerifyProductModule.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;AAEb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;AAEb;AACA;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;AACA;IACI;QACI,uBAAuB;QACvB,uBAAuB;IAC3B;;AAEJ","sourcesContent":["#verification-product{\n    padding: 1rem 5rem 2rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    align-items: center;\n}\n#verification-product form{\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n\n}\n#verification-product form .not-main-p{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n\n}\n@media (max-width: 992px){\n\n#verification-product {\n    padding: 1rem 2rem 2rem;\n}\n\n}\n@media (max-width: 768px){\n    #verification-product {\n        padding: 1rem 1rem 2rem;\n        justify-content: center;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
